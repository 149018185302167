import React from 'react';

function HeroCardScreen() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md rounded-lg overflow-hidden shadow-lg">
        <img className="w-full h-64 object-cover h-12 w-24" src="https://source.unsplash.com/random/800x600" alt="Hero Card" />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Bespoke HR</div>
          <p className="text-gray-700 text-base">
            Welcome!  We're currently building out our U.S. website.  In the meantime, we have opportunities for independent HR contractors!  For details on the opportunity and the process, as well as instructions on applying,<br />
            <br />
            <h1><a href='../../pages/contract-hr.html'>click here</a></h1>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroCardScreen;
